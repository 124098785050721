<template>
  <b-card>
    <b-row>
      <b-col>
        <h1 class="mb-2">
          Your profile
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          v-if="isLoading"
          class="text-center pt-3"
        >
          <b-spinner label="Loading..." />
        </div>
        <validation-observer
          v-else
          ref="profileValidation"
        >
          <b-form
            class="d-flex flex-column"
            @submit="onSubmit"
          >
            <b-form-group
              label="Name:"
              label-for="input-name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Enter your name"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Email:"
              label-for="input-email"
              description="We'll never share your email with anyone else."
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="input-email"
                  v-model="form.email"
                  placeholder="Enter your email"
                  type="email"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Phone number:"
              label-for="input-phone"
              description="We'll never share your phone with anyone else."
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required|min:12"
              >
                <phone-mask-input
                  v-model="form.phone"
                  defaultCountry="us"
                  inputClass="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <template v-if="user && user.role === 'Captain'">
              <b-form-group
                label="My Bio:"
                label-for="input-bio"
                class="mb-2"
              >
                <b-form-textarea
                  v-model="form.bio"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                />
              </b-form-group>

              <div class="d-flex flex-wrap flex-sm-nowrap">
                <b-img
                  thumbnail
                  rounded="circle"
                  fluid
                  :src="image"
                  class="profile-image mr-3"
                />
                <b-form-group
                  label="My Headshot:"
                  label-for="input-image"
                  class="mb-2 w-100 mt-2 mt-sm-0"
                >
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="onFileChange"
                  />
                </b-form-group>
              </div>
            </template>

            <template v-if="user.role === 'Customer'">
              <h3>
                Payment Methods
              </h3>
              <div
                v-if="isPaymentLoading"
                class="text-center pt-3"
              >
                <b-spinner label="Loading..." />
              </div>
              <b-form-radio-group
                v-else
                id="radio-group"
                class="mt-2"
                v-model="card"
                name="radio-cards"
              >
                <div class="d-flex flex-wrap">
                  <b-card
                    v-for="card in cards"
                    :key="card.id"
                    class="mr-2 payment-card"
                    :class="card.active ? 'border-primary' : 'border-secondary'"
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <b-form-radio
                        :value="card.id"
                      >
                        <h3 class="mb-0">
                          {{ card.brand }}
                        </h3>
                      </b-form-radio>

                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        right
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle"
                          />
                        </template>
                        <b-dropdown-item @click="deletePaymentMethod(card.id)">
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span>Delete</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                    <div class="d-flex">
                      <h4>
                        •••• {{ card.last4 }}
                      </h4>
                      <span>(expires {{ card.exp_month }}/{{ card.exp_year }})</span>
                    </div>
                  </b-card>
                  <b-card
                    class="border-secondary cursor-pointer payment-card"
                    v-b-modal.add-card-modal
                  >
                    <div class="d-flex align-items-center h-100 justify-content-center">
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span>Add a new card</span>
                    </div>
                  </b-card>
                </div>
              </b-form-radio-group>
            </template>

            <b-button
              type="submit"
              variant="primary"
              class="align-self-end w-xs-100 w-25 mt-1"
              :disabled="isEditLoading"
            >
              <b-spinner
                v-if="isEditLoading"
                label="Spinning"
                small
              />
              <span v-else>
                Save edits
              </span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>

      <b-modal
        id="add-card-modal"
        title="Add a new card"
        cancel-variant="outline-secondary"
      >
        <stripe-elements
          #default="{ elements }"
          ref="elms"
          :stripe-key="stripePublishKey"
          :elements-options="elementsOptions"
        >
          <stripe-element
            ref="card"
            type="card"
            :elements="elements"
            :options="cardOptions"
            @change="errorHandler($event)"
          />
        </stripe-elements>
        <div
          id="card-errors"
          class="mt-1"
          role="alert"
          v-text="errorMessage"
        />
        <template #modal-footer>
          <b-button
            variant="outline-secondary"
            class="float-right"
            @click="$bvModal.hide('add-card-modal')"
          >
            Close
          </b-button>
          <b-button
            variant="primary"
            class="float-right"
            :disabled="isAddCardLoading"
            @click="addCardSubmit"
          >
            <b-spinner
              v-if="isAddCardLoading"
              label="Spinning"
              small
            />
            <span v-else>
              Save
            </span>
          </b-button>
        </template>
      </b-modal>
    </b-row>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BFormTextarea,
  BImg,
  BFormFile,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { toastSuccess, toastFailure } from '@/libs/toastification'
import { StripeElements, StripeElement } from "vue-stripe-elements-plus"
import PhoneMaskInput from  "vue-phone-mask-input"
import {sweetWarning, sweetError} from "@/libs/sweet-alerts";
import {testStripeToken} from "@/libs/stripe-test-token";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    StripeElements,
    StripeElement,
    PhoneMaskInput,
    BFormTextarea,
    BImg,
    BFormFile,
  },
  data() {
    return {
      isLoading: true,
      isPaymentLoading: true,
      form: {
        name: null,
        email: null,
        phone: '+1',
        bio: '',
        headshot: null,
      },
      image: null,

      card: null,
      required,
      email,
      isEditLoading: false,
      isAddCardLoading: false,
      errorMessage: '',
      stripePublishKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      elementsOptions: {
        locale: "en-US",
      },
      cardOptions: {
        hidePostalCode: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      cards: 'cards/cards',
      payments: 'payments/payments',
    }),
  },
  mounted() {
    this.form = { ...this.user }
    this.image = this.user.picture || process.env.VUE_APP_BACKEND_URL + '/storage/captains/0.jpg'

    this.isLoading = false

    this.loadCards().then(() => {

      if (this.cards !== null && this.cards.length) {
        const activeCard = this.cards.find(card => card.active)
        this.card = activeCard ? activeCard.id : null
      }

      this.isPaymentLoading = false

      this.loadPayments({
        id: this.user.id
      }).then(() => {

      });
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
      loadCards: 'cards/loadCards',
      loadPayments: 'payments/loadPayments',
      createCard: 'cards/createCard',
      deleteCard: 'cards/deleteCard',
    }),
    onSubmit(event) {
      event.preventDefault()
      this.$refs.profileValidation.validate().then(success => {
        if (success) {
          this.isEditLoading = true
          this.submitForm()
        }
      })
    },
    showError(responseData=null, warning=false) {

      if( responseData != null && responseData.message != undefined && responseData.message != null ){

        let errorMSG = responseData.message + '</br>';
        if(responseData.errors){

          Object.keys(responseData.errors).map(function(errorKey){
            responseData.errors[errorKey].map(function(value, key){
              errorMSG += value + '</br>';
            });
          });
        }

        if( warning ){
          sweetWarning('Warning', errorMSG);
        }
        else{
          sweetError('Error', errorMSG);
        }
      }
      else{
        toastFailure("Something went wrong.");
      }
    },
    submitForm() {
      const formData = new FormData()

      formData.append('id', this.user.id)
      formData.append('name', this.form.name)
      formData.append('email', this.form.email)
      formData.append('phone', this.form.phone)
      if (this.form.bio) formData.append('bio', this.form.bio)
      if (this.form.headshot) formData.append('picture', this.form.headshot)
      if (this.card) {
        const cardIndex = this.cards.findIndex(card => card.id === this.card)
        formData.append('card', JSON.stringify(this.cards[cardIndex]))
        formData.append('card_id', this.card)
      }
      formData.append('_method', 'POST')

      this.updateUser(formData)
        .then(() => {
          toastSuccess('Your changes have been updated')
        })
        .catch(() => {
          toastFailure('Something went wrong')
        })
        .finally(() => {
          this.isEditLoading = false
        })
    },
    addCardSubmit() {
      const groupComponent = this.$refs.elms
      const cardComponent = this.$refs.card
      const cardElement = cardComponent.stripeElement

      groupComponent.instance.createToken(cardElement).then((response) => {
        if (!response.error) {
          this.isAddCardLoading = true

          let tokenId = '';
          let last4 = response.token.card.last4;

          let testTokenId = testStripeToken(last4)
          if( testTokenId ){
            tokenId = testTokenId;
          }
          else{
            tokenId = response.token.id;
          }

          this.createCard({ 'stripe_token': tokenId })
            .then(() => {
              this.card = this.cards.find(card => card.active).id;
              this.$root.$emit('bv::hide::modal', 'add-card-modal');
              toastSuccess('Your card has been added');
            })
            .catch((error) => {
              //toastFailure('Something went wrong');
              this.showError(error.response.data);
            })
            .finally(() => {
              this.isAddCardLoading = false
            })
        }
      })
    },
    errorHandler(event) {
      this.errorMessage = event.error ? event.error.message : ""
    },
    deletePaymentMethod(cardId) {

      if( this.cards.length < 2 && this.payments.length > 0 ){

        this.showError({message: 'You must have at least one active card on file.'}, 1);
        return false;
      }

      this.deleteCard(cardId)
        .then(() => {
          if (this.cards.length) {
            this.card = this.cards.find(card => card.active).id
          }
          toastSuccess("Card has been deleted.");
        })
        .catch(() => {
          toastFailure("Something went wrong.");
        });
    },
    onFileChange(event) {
      this.form.headshot = event.target.files[0]

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.image = reader.result
      })

      if (this.form.headshot){
        if (/\.(jpe?g|png|gif)$/i.test(this.form.headshot.name)) {
          reader.readAsDataURL(this.form.headshot)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "../assets/scss/profile.scss";
@import "../assets/scss/stripeStyles.scss";
</style>
